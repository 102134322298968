import * as React from 'react';
import Layout from '../components/layout';
import { Link, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

const BlogPosts = ({ data }) => {
   // console.log('hiiiiiiiii');
   console.log('blogPosts-data-indi', data);
   return (
      <Layout pageTitle="My Blog Posts">
         <p>My cool posts will go in here</p>
         <ul>
            {data.allFile.nodes.map((node) => (
               <article key={node.childMdx.id}>
                  {console.log('node', node)}
                  <h2>
                     <Link to={`/blogPosts/${node.childMdx.slug}`}>
                        {node.childMdx.frontmatter.title}
                     </Link>
                  </h2>
                  <p>
                     Posted:
                     {node.childMdx.frontmatter.date}
                     {/* {node.frontmatter.date} */}
                  </p>
               </article>
            ))}
            {/* <h2>{node.frontmatter.title}</h2> */}
            {/* <MDXRenderer>{node.body}</MDXRenderer> */}

            {/* {data.allMdx.nodes.map((node) => (
               <article key={node.id}>
                  <h2>{node.frontmatter.title}</h2>
                  <p>Posted: {node.frontmatter.date}</p>
                  <MDXRenderer>{node.body}</MDXRenderer>
               </article>
            ))} */}
         </ul>
      </Layout>
   );
};

// export const query = graphql`
// export const query123123 = graphql`
//    query {
//       allFile {
//          nodes {
//             name
//          }
//       }
//    }
// `;

// export const query123123 = graphql`
//    query {
//       allFile {
//          nodes {
//             name
//          }
//       }
//    }
// `;

// export const query = graphql`
//    query {
//       allMdx(sort: { fields: frontmatter___date, order: DESC }) {
//          nodes {
//             frontmatter {
//                date(formatString: "MMMM D, YYYY")
//                title
//             }
//             id
//             body
//          }
//       }
//    }
// `;

// limit: 2;

export const query = graphql`
   query {
      allFile(
         sort: { fields: [childrenMdx___frontmatter___date], order: DESC }
         filter: { sourceInstanceName: { eq: "blogPosts" } }
      ) {
         nodes {
            childMdx {
               frontmatter {
                  date(formatString: "MMMM D, YYYY")
                  title
               }
               id
               slug
            }
         }
      }
   }
`;

export default BlogPosts;
